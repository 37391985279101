<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <toolbar :title="$t('admin.route.dataTypes')">
        <v-text-field
          class="d-none d-md-flex mx-1"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          style="width:0"
        ></v-text-field>
        <v-divider vertical />
        <v-btn
          small
          text
          color="info"
          class="mx-2"
          @click="recache"
        >
          <v-icon>mdi-database-refresh</v-icon>
          <span class="text--info">{{ $t("general.recache") }}</span>
        </v-btn>
      </toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(84)">
        <v-data-table
          dense
          :headers="headers"
          :items="getTypes"
          item-key="id"
          :search="search"
          single-select
          @click:row="navigateToSelected"
        >
          <template #item.name="{ item }">
            <v-btn text tile>
              <v-icon small class="mr-2">mdi-cog-outline</v-icon>
              {{ $t(item.locale) }}</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";

export default {
  name: "Types",
  components: { ViewContainer, Toolbar },
  props: {
    route: String,
  },
  data() {
    return {
      search: "",
      headers: [{ text: this.$t("general.name"), value: "name" }],
    };
  },
  computed: {
    ...mapGetters("types", ["getTypeGroups"]),

    getTypes() {
      const list = this.getTypeGroups();
      return list.filter((f) => this.$allowed(f.permission));
    },
  },
  methods: {
    ...mapActions("types", ["recacheTypes"]),

    navigateToSelected(group) {
      const { route } = group;
      this.$$router.push({ name: "admin.type", params: { route, back: { name: this.$route.name } } });
    },
    recache() {
      this.recacheTypes();
    }
  },
};
</script>
